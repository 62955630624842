<template>
  <div class="mb-10">
    <v-container fluid>
      <v-row dense>
        <v-col lg="8" offset-lg="2">
          <!--Header-->
          <slot name="formTitle"></slot>
          <v-card>
            <v-form
              v-if="!firstLoad"
              @submit.prevent="action.value"
              @input="validateForm()"
              class="pa-3"
              ref="form"
              id="form"
              :value="valid"
              :lazy-validation="true"
            >
              <slot name="formFields"></slot>
            </v-form>
            <!--Loading state-->
            <v-card
              v-else
              elevation="0"
              max-height="450px"
              height="450"
              class="text-center extra-padding-top"
            >
              <v-progress-circular
                indeterminate
                color="primary"
                :size="70"
                :width="7"
              ></v-progress-circular>
            </v-card>
            <!--Actions-->
            <slot name="formActions"></slot>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "FormWrapper",
  props: ["fields", "action", "valid", "firstLoad"],
  data() {
    return {};
  },
  methods: {
    validateForm() {
      const validateForm = this.$refs.form.validate();
      validateForm;
      this.$emit("passValid", validateForm);
    },
  },
};
</script>
<style scoped>
.extra-padding-top {
  padding-top: 180px;
}
</style>